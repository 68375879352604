/****************************** components ******************************/
@import "./components/mixin";
@import "./components/default";
@import "./components/normalize";
@import "./components/reset";
@import "./components/grid";
//@import "./components/case";//例子
/****************************** style ******************************/

body,
button,
input,
select,
textarea {
    font: 12px/1.4 "\5FAE\8F6F\96C5\9ED1", "arial";
    color: #5a5a5a;
    font-size: 12px;
}
body {
    max-width: 1920px;
    margin: 0 auto;
}
img {
    max-width: 100%;
    display: block;
}

.header {
    width: 100%;
    height: .5rem;
    background: #fff;
    background-size: 100% 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    box-shadow: 0px 1px 10px 1px #ddd;
    .wap_nav {
        width: .4rem;
        float: left;
        position: absolute;
        left: 0;
        top: 0;
        .menu {
            float: left;
            line-height: .5rem;
            padding: 0 .1rem;
            img {
                vertical-align: middle;
                display: inline-block;
            }
        }
        .app_menu {
            width: 100%;
            position: fixed;
            top: .5rem;
            left: 0;
            z-index: 100;
            display: none;
            box-shadow: 0px 2px 5px 0px #ddd;
            ul {
                background: #fff;   
                li {
                    padding-left: .2rem;
                    width: 100%;
                    border-top: 1px solid #ddd;
                    outline: 0;
                    a {
                        width: 100%;
                        display: block;
                        font-size: .1rem;
                        width: 100%;
                        height: .3rem;
                        line-height: .3rem;
                        color: #999;
                        display: block;
                        padding-left: .1rem;
                    }
                }
            }
        }
    }
    .logo {
        width: 100%;
        a {
            display: block;
            padding: 0 .1rem;
            padding-top: .075rem;
            img {
                height: .35rem;
                margin: 0 auto;
                display: block;
            }
        }
        span {
            float: left;
            line-height: 66px;
        }
    }
    .mtel {
        position: absolute;
        right: 0;
        top: 0;
        padding-top: .085rem;
        padding-right: .05rem;
        span {
            font-size: .12rem;
            color: #046eb8;
            font-weight: bold;
            padding-left: .18rem;
            background: url(../img/tel.png) no-repeat left center;
            background-size: .15rem;
            line-height: .18rem;
        }
        p {
            font-size: .12rem;
            color: #046eb8;
            font-weight: bold;
            line-height: .16rem;
        }
    }
}

.mtheader {
    margin-top: .5rem;
}

.banner {
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 9;
    .wapbanner {
        .swiper-slide {
            img {
                width: 100%;
                display: block;
            }
        }
        .pagination {
            text-align: center;
            position: absolute;
            bottom: 5px;
            width: 100%;
            z-index: 99;
            span {
                width: 9px;
                height: 9px;
                border-radius: 50%;
                display: inline-block;
                text-indent: -999px;
                overflow: hidden;
                overflow: hidden;
                cursor: pointer;
                background: #eee;
                margin: 0 5px;
                opacity: 1;
                &.swiper-pagination-bullet-active {
                    background: #046eb8;
                    color: #fff;
                }
            }
        }
    }
}

.box_tit {
    p {
        display: block;
        text-align: center;
        position: relative;
        font-size: .16rem;
        color: #39393a;
        font-weight: bold;
        line-height: .2rem;
        letter-spacing: 1px;
        i {
            font-style: normal;
            color: #f39700;
            font-weight: bold;
        }
        &:before {
            position: absolute;
            top: .105rem;
            left: .75rem;
            content: '';
            width: .24rem;
            height: 1px;
            background: #000;
        }
        &:after {
            position: absolute;
            top: .105rem;
            right: .75rem;
            content: '';
            width: .24rem;
            height: 1px;
            background: #000;
        }
    }
    span {
        font-size: .12rem;
        color: #39393a;
        display: block;
        text-align: center;
        font-family: arial;
        letter-spacing: 2px;
        margin-top: 6px;
    }
    em {
        font-size: .12rem;
        color: #39393a;
        display: block;
        margin-top: 6px;
        text-align: center;
        padding: 0 3%;
        line-height: 150%;
    }
}

.i_box1 {
    padding: .15rem .05rem;
    overflow: hidden;
    .box1list {
        margin-top: .15rem;
        border-left: 1px solid #ddd;
        li {
            width: 25%;
            float: left;
            border: 1px solid #ddd;
            border-left: none;
            span {
                width: 100%;
                height: .6rem;
                line-height: .6rem;
                display: block;
                text-align: center;
                img {
                    max-height: .35rem;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            i {
                font-style: normal;
                font-weight: bold;
                font-size: .11rem;
                color: #39393a;
                display: block;
                text-align: center;
                margin-bottom: .03rem;
            }
            p {
                font-size: .09rem;
                text-align: center;
                color: #b0b0b3;
                line-height: .14rem;
                height: .28rem;
            }
        }
    }
}

.m_more {
    margin-top: .15rem;
    a {
        width: 1.2rem;
        padding-right: .05rem;
        line-height: .32rem;
        display: block;
        border-radius: .2rem;
        margin: 0 auto;
        font-size: .12rem;
        text-align: center;
        color: #fff;
        letter-spacing: 2px;
        background: url(../img/ri.png) no-repeat .88rem center #016db7;
        background-size: .07rem;
    }
}

.i_box2 {
    padding: .2rem .05rem;
    overflow: hidden;
    background: url(../img/bg1.jpg) no-repeat top center #016db7;
    .box_tit {
        p {
            color: #fff;
            &:before {
                background: #fff;
            }
            &:after {
                background: #fff;
            }
        }
        span {
            color: #fff;
        }
    }
    .box2list {
        margin-top: .15rem;
        li {
            width: 33.33%;
            padding: 0 1.5%;
            float: left;
            img {
                display: block;
                max-width: 100%;
                margin: 0 auto;
            }
            span {
                font-size: .12rem;
                color: #fff;
                font-weight: bold;
                text-align: center;
                display: block;
                margin-top: .1rem;
            }
            p {
                font-size: .1rem;
                color: #fff;
                text-align: center;
                margin-top: .06rem;
                line-height: .16rem;
                min-height: 1.12rem;
            }
            a {
                width: .6rem;
                padding-right: .05rem;
                line-height: .26rem;
                display: block;
                border-radius: .2rem;
                margin: 0 auto;
                font-size: .12rem;
                text-align: center;
                color: #016db7;
                letter-spacing: 2px;
                background: url(../img/ri2.png) no-repeat .44rem center #fff;
                background-size: .07rem;
            }
        }
    }
    .m_more2 {
        margin-top: .25rem;
    }
}

.m_more2 {
    margin-top: .15rem;
    a {
        width: 1.8rem;
        padding-right: .05rem;
        line-height: .3rem;
        display: block;
        border-radius: .2rem;
        margin: 0 auto;
        font-size: .12rem;
        font-weight: bold;
        text-align: center;
        color: #016db7;
        background: #fff;
        background-size: .07rem;
    }
}

.i_box3 {
    padding: .15rem .05rem;
    overflow: hidden;
    .box_tit {
        p {
            &:before {
                left: .9rem;
            }
            &:after {
                right: .9rem;
            }
        }
    }
    .m_more {
        margin-top: .1rem;
        a {
            width: 1.4rem;
            background: url(../img/ri.png) no-repeat 1.15rem center #016db7;
            background-size: .07rem;
        }
    }
}
.box3list {
    position: relative;
    padding-bottom: .25rem;
    margin-top: .15rem;
    .swiper-slide {
        margin-bottom: .1rem;
        .text {
            background: url(../img/libg1.png) no-repeat top left;
            padding: .15rem .05rem;
            background-size: 100% 100%;
            span {
                font-size: .11rem;
                color: #39393a;
                display: block;
                font-weight: bold;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            p {
                font-size: .09rem;
                line-height: .15rem;
                height: .45rem;
                overflow: hidden;
                margin-top: .05rem;
            }
        }
        .pic {
            margin-top: .03rem;
            position: relative;
            min-height: .5rem;
            img {
                position: absolute;
                left: 0;
                top: 0;
                width: .5rem;
                float: left;
            }
            p {
                width: 100%;
                padding-top: .05rem;
                padding-left: .55rem;
                float: left;
                span {
                    display: block;
                    font-size: .115rem;
                    color: #39393a;
                    display: block;
                    font-weight: bold
                }
                i {
                    display: block;
                    font-size: .09rem;
                    font-style: normal;
                    color: #666;
                    margin-top: .03rem;
                }
            }
        }
    }
    .pagination {
        text-align: center;
        position: absolute;
        bottom: 5px;
        width: 100%;
        z-index: 99;
        span {
            width: 9px;
            height: 9px;
            border-radius: 50%;
            display: inline-block;
            text-indent: -999px;
            overflow: hidden;
            overflow: hidden;
            cursor: pointer;
            background: none;
            border: 1px solid #666;
            margin: 0 5px;
            opacity: 1;
            &.swiper-pagination-bullet-active {
                background: #046eb8;
                border-color: #046eb8;
            }
        }
    }
}

.i_box4 {
    padding: .15rem .05rem;
    overflow: hidden;
    .box_tit {
        p {
            &:before {
                left: .9rem;
            }
            &:after {
                right: .9rem;
            }
        }
    }
}
.box4_menu {
    margin-top: .15rem;
    text-align: center;
    li {
        width: .7rem;
        line-height: .3rem;
        background: #f59701;
        display: inline-block;
        font-size: .11rem;
        color: #fff;
        text-align: center;
        margin: 0 .03rem;
        &:hover {
            background: #016db7;
        }
        &.active {
            background: #016db7;
        }
    }
}
.box4list {
    margin-top: .15rem;
    li {
        float: left;
        width: 49%;
        margin-right: 2%;
        margin-bottom: .05rem;
        .imgauto {
            width: 100%;
            height: .96rem;
        }
        span {
            font-size: .11rem;
            color: #39393a;
            display: block;
            text-align: center;
            line-height: .24rem;
        }
        &:nth-child(2n) {
            margin-right: 0;
        }
    }
}

.i_box5 {
    padding: .15rem .05rem;
    overflow: hidden;
    .box_tit {
        p {
            &:before {
                left: .9rem;
            }
            &:after {
                right: .9rem;
            }
        }
    }
}
.box5list {
    margin-top: .15rem;
    text-align: center;
    li {
        width: 33.33%;
        float: left;
        padding: 0 2%;
        display: inline-block;
        img {
            display: block;
            max-width: 85%;
            margin: 0 auto;
        }
        span {
            font-size: .11rem;
            color: #000;
            display: block;
            text-align: center;
            margin-top: .04rem;
            margin-bottom: .03rem;
        }
        p {
            font-size: .09rem;
            color: #666;
            text-align: center;
            line-height: .15rem;
            height: .45rem;
        }
        &:first-child +li +li +li {
            margin-left: 16.33%;
        }
    }
}

.i_box6 {
    padding: .15rem 0;
    overflow: hidden;
    background: #e7e7e7;
    background-size: 100%;
    position: relative;
    .tit {
        img {
            max-width: 70%;
            margin: 0 auto;
        }
        p {
            font-size: .1rem;
            line-height: 160%;
            padding: 0 .1rem;
            color: #036eb8;
            display: block;
            text-align: center;
            margin-top: .15rem;
        }
    }
    .text {
        margin-top: .1rem;
        img {
            display: block;
            width: 100%;
        }
    }
    .m_more2 {
        position: absolute;
        width: 100%;
        bottom: .13rem;
        left: 0;
        margin-top: 0;
    }
}

.i_box7 {
    padding: .15rem .05rem;
    overflow: hidden;
    .box_tit {
        p {
            &:before {
                left: .9rem;
            }
            &:after {
                right: .9rem;
            }
        }
    }
}
.box7_mne {
    margin-top: .15rem;
    a {
        width: 50%;
        float: left;
        font-size: .11rem;
        color: #fff;
        line-height: .3rem;
        text-align: center;
        background: #016db7;
        &:last-child {
            background: #f59701;
        }
    }
}
.box7list {
    margin-top: .05rem;
    .pic {
        position: relative;
        img {
            width: 100%;
            display: block;
        }
        span {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            line-height: .3rem;
            background: rgba(0,0,0,.4);
            color: #ddd;
            font-size: .115rem;
            text-align: center;
        }
    }
    .list {
        padding-top: .03rem;
        li {
            width: 100%;
            float: left;
            line-height: .27rem;
            border-bottom: 1px solid #eee;
            a {
                width: 60%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                float: left;
                font-size: .11rem;
                color: #000;
            }
            span {
                float: right;
                float: .1rem;
            }
        }
    }
}

.i_box8 {
    padding: .15rem .05rem;
    overflow: hidden;
    background: url(../img/bg3.png) no-repeat top center #3a3845;
    p {
        font-size: .12rem;
        color: #fff;
        text-align: center;
        i {
            font-style: normal;
            color: #f59701;
            padding: 0 3px;
            font-size: .16rem;
        }
    }
    span {
        font-size: .11rem;
        text-align: center;
        display: block;
        color: #fff;
        margin-top: .03rem;
        margin-bottom: .15rem;
    }
    input {
        width: 1.4rem;
        height: .3rem;
        margin: 0 auto;
        font-size: .1rem;
        border-radius: .3rem;
        background: #fff;
        border: none;
        line-height: .3rem;
        padding: 0 .1rem;
        color: #7c7c7c;
        outline: 0;
        display: block;
        margin-bottom: .1rem;
    }
    .btn_sub {
        width: 1rem;
        height: .3rem;
        background: #036eb8;
        color: #fff;
        line-height: .3rem;
        margin-left: .85rem;
    }
}

.footer {
    padding: .2rem .1rem;
    background: url(../img/fbg.png) no-repeat bottom center #242831;
    overflow: hidden;
    .fl {
        width: 100%;
        span {
            padding-left: .3rem;
            display: block;
            background: url(../img/tel2.png) no-repeat left center;
            background-size: .26rem;
            margin-bottom: .05rem;
            i {
                font-size: .12rem;
                color: #f39700;
                display: block;
                font-style: normal;
                &:last-child {
                    font-size: .14rem;
                    font-weight: bold;
                }
            }
        }
        p {
            font-size: .11rem;
            color: #fff;
            display: block;
            line-height: 160%;
            margin-bottom: .03rem;
        }
    }
    .fr {
        width: 100%;
        margin-top: .05rem;
        img {
            max-width: 1rem;
            display: block;
            padding: 5px;
            background: #fff;
        }
        span {
            font-size: .11rem;
            width: 1rem;
            display: block;
            text-align: center;
            margin-top: .03rem;
            color: #fff;
        }
    }
}

.mtfix {
    margin-top: .5rem;
}
.fix_text {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    .text {
        a {
            width: 18%;
            float: left;
            padding-top: .32rem;
            padding-bottom: .08rem;
            background: #036eb8;
            font-size: .11rem;
            line-height: 100%;
            color: #fff;
            text-align: center;
        }
        .a1 {
            background: url(../img/fic1.png) no-repeat center .07rem #036eb8;
            background-size: .2rem;
        }
        .a2 {
            background: url(../img/fic2.png) no-repeat center .07rem #036eb8;
            background-size: .2rem;
        }
        .a3 {
            background: url(../img/fic3.png) no-repeat center .07rem #036eb8;
            background-size: .2rem;
        }
        .a4 {
            background: url(../img/fic4.png) no-repeat center .07rem #036eb8;
            background-size: .2rem;
        }
        .a0 {
            width: 28%;
            padding: 0;
            height: .508rem;
            position: relative;
            img {
                position: absolute;
                bottom: 0;
                left: .098rem;
                width: .7rem;
                height: .7rem;
                background: #fff;
                border-radius: 50%;
                padding: .1rem;
            }
        }
    }
}

.pbox_tit {
    p {
        &:before {
            left: .9rem;
        }
        &:after {
            right: .9rem;
        }
    }
    em {
        font-size: .11rem;
    }
}

.hy_box1 {
    padding: .15rem .05rem;
}
.hy1list {
    margin-top: .15rem;
    li {
        width: .93rem;
        height: .93rem;
        margin: 0 .05rem;
        float: left;
        border-radius: 50%;
        border: 1px dotted #036eb8;
        float: left;
        padding: .05rem;
        margin-bottom: .1rem;
        .pic {
            background: #036eb8;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            p {
                line-height: .5rem;
                text-align: center;
                img {
                    @include inline-block();
                    vertical-align: middle;
                    height: .28rem;
                }
            }
            span {
                font-size: .115rem;
                color: #fff;
                display: block;
                text-align: center;
            }
        }
        &:hover {
            .pic {
                p {
                    img {
                        transform: rotateY(360deg);
                        transition: .5s;
                    }
                }
            }
        }
        &:first-child +li +li +li {
            margin-left: .515rem;
        }
    }
}

.hy_box2 {
    width: 100%;
    overflow: hidden;
    background: url(../img/bg4.jpg) no-repeat top center;
    padding: .15rem .05rem;
    .fl {
        width: 100%;
        .text {
            width: 1.4rem;
            height: 1.1rem;
            margin: 0 auto;
            background: url(../img/b.png) no-repeat top center;
            background-size: 100% 100%;
            span {
                padding-top: .1rem;
                line-height: 100%;
                font-size: .18rem;
                color: #fff;
                display: block;
                text-align: center;
            }
            p {
                font-size: .1rem;
                color: #fff;
                display: block;
                line-height: 170%;
                padding: 0 .07rem;
                text-align: center;
                margin-top: .08rem;
            }
            strong {
                font-size: .18rem;
                color: #fff;
                display: block;
                text-align: center;
                margin-top: .06rem;
            }
        }
    }
    .fr {
        width: 100%;
        margin-top: .05rem;
        li {
            float: left;
            width: .93rem;
            height: .6rem;
            margin: 0 .05rem;
            margin-top: .1rem;
            position: relative;
            a {
                img {
                    display: block;
                    width: 100%;
                }
                span {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,.5);
                    line-height: .6rem;
                    font-size: .11rem;
                    color: #fff;
                    text-align: center;
                }
            }
        }
    }
}

.hy_box3 {
    padding: .15rem .05rem;
}
.hy3list {
    margin-top: .15rem;
    li {
        width: 49%;
        background: #fff;
        margin-right: 2%;
        padding: .05rem;
        float: left;
        box-shadow: 0px 1px 10px 1px #ddd;
        position: relative;
        margin-bottom: .1rem;
        &:nth-child(2n) {
            margin-right: 0;
        }
        .fl {
            width: 100%;
            img {
                height: 1.115rem;
            }
        }
        .fr {
            width: 100%;
            span {
                font-size: .12rem;
                color: #036eb8;
                display: block;
                font-weight: bold;
                padding: .04rem 0 .05rem 0;
            }
            p {
                font-size: .095rem;
                color: #555;
                display: block;
                line-height: 170%;
            }
            .a_more {
                width: 100%;
                margin-top: .05rem;
                a {
                    width: 48%;
                    float: left;
                    height: .24rem;
                    padding-left: .07rem;
                    line-height: .24rem;
                    color: #fff;
                    font-size: .09rem;
                    @include inline-block();
                    &:first-child {
                        background: url(../img/more1.png) no-repeat center;
                        background-size: 100% 100%;
                        margin-right: 4%;
                    }
                    &:first-child +a {
                        background: url(../img/more2.png) no-repeat center;
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }
}

.ky_box1 {
    padding: .15rem .05rem;
}

@keyframes myfirst {
    0% {
        transform: translate(0px, 0px);
    }
    50% {
        transform: translate(0px, -.1rem);
    }
    100% {
        transform: translate(0px, 0px);
    }
}
.ky1list {
    margin-top: .15rem;
    li {
        width: 1.03rem;
        float: left;
        margin-bottom: .1rem;
        .pic {
            p {
                width: .73rem;
                height: .73rem;
                margin: 0 .15rem;
                border-radius: 50%;
                background: #fff;
                box-shadow: 0px 1px 10px 1px #ddd;
                line-height: .73rem;
                text-align: center;
                position: relative;
                transition: .8s;
                img {
                    height: .3rem;
                    @include inline-block();
                }
            }
            span {
                font-size: .12rem;
                color: #036eb8;
                display: block;
                text-align: center;
                margin-top: .08rem;
            }
            i {
                font-style: normal;
                width: .3rem;
                height: 2px;
                background: #036eb8;
                font-style: normal;
                display: block;
                margin: 0 auto;
                margin-top: .05rem;
            }
        }
        &:hover {
            .pic {
                animation: myfirst .8s alternate;
            }
        }
    }
}

.ky_box2 {
    padding: .15rem .05rem;
    background: url(../img/a14.jpg) no-repeat top center;
    .box_tit {
        p {
            color: #fff;
            &:before {
                background: #fff;
            }
            &:after {
                background: #fff;
            }
        }
        em {
            color: #fff;
        }
    }
}
.ky2text {
    margin-top: .05rem;
    li {
        float: left;
        width: 1.5rem;
        margin-right: .1rem;
        margin-top: .1rem;
        position: relative;
        &:nth-child(2n) {
            margin-right: 0;
        }
        img {}
        .text {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.6);
            span {
                font-size: .12rem;
                color: #fff;
                display: block;
                text-align: center;
                margin-top: .08rem;
            }
            i {
                display: block;
                width: .15rem;
                height: 2px;
                background: #fff;
                font-style: normal;
                margin: .07rem auto;
            }
            p {
                font-size: .095rem;
                color: #fff;
                text-align: center;
                line-height: 160%;
            }
        }
    }
}

.ly_box1 {
    width: 100%;
    overflow: hidden;
    padding: .15rem .05rem;
}
.ly1list {
    position: relative;
    margin-top: .15rem;
    img {
        display: block;
        width: 100%;
    }
}

.ly_box2 {
    padding: .15rem .05rem;
    overflow: hidden;
    background: url(../img/a15.jpg) no-repeat top center #12588a;
    .box_tit {
        p {
            color: #fff;
            &:before {
                background: #fff;
            }
            &:after {
                background: #fff;
            }
        }
        em {
            color: #fff;
        }
    }
}
.ly2text {
    margin-top: .15rem;
    li {
        width: 1.5rem;
        margin-right: .1rem;
        margin-bottom: .1rem;
        background: #fff;
        float: left;
        height: 1.7rem;
        &:nth-child(2n){
            margin-right: 0;
        }
        span {
            height: .6rem;
            width: 100%;
            line-height: .6rem;
            display: block;
            text-align: center;
            img {
                height: .3rem;
                @include inline-block();
                vertical-align: middle;
            }
        }
        i {
            font-style: normal;
            font-size: .13rem;
            color: #000;
            display: block;
            text-align: center;
            font-weight: bold;
            margin-bottom: .05rem;
        }
        p {
            font-size: .095rem;
            color: #000;
            text-align: center;
            line-height: 170%;
        }
        &:first-child {
            margin-left: 0;
        }
    }
}

.page_nav {
    padding: .06rem .05rem;
    text-align: center;
    li {
        width: 32%;
        float: left;
        border: 1px solid #dcdcdc;
        margin: .035rem 0;
        margin-right: 2%;
        background: #f3f4f6;
        a {
            font-size: .115rem;
            color: #b9b9b9;
            text-align: center;
            display: block;
            line-height: .26rem;
        }
        &.on {
            background: #0083ce;
            border-color: #0083ce;
            a {
                color: #fff;
            }
        }
        &:nth-child(3n) {
            margin-right: 0;
        }
    }
}

.new_list {
    padding: 0 .05rem;
    background: #fff;
    li {
        width: 100%;
        float: left;
        margin: .1rem 0;
        position: relative;
        .pic {
            width: 1rem;
            float: left;
            position: relative;
            img {
                width: 100%;
                display: block;
            }
        }
        .item {
            width: 2rem;
            float: right;
            a {
                font-size: .11rem;
                color: #666;
                display: block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            span {
                display: block;
                font-size: .09rem;
                color: #999;
                line-height: .15rem;
                height: .3rem;
                overflow: hidden;
                margin-top: .04rem;
            }
            p {
                position: absolute;
                bottom: 0;
                font-size: .09rem;
                line-height: .16rem;
                color: #b1b1b1;
                padding-left: .1rem;
                background: url(../img/time.png) no-repeat left center;
                background-size: .08rem;
            }
        }
    }
    .line {
        width: 100%;
        float: left;
        border-bottom: 1px solid #eee;
    }
}

.pro_list {
    padding: 0 .05rem;
    li {
        width: 49%;
        float: left;
        margin-right: 2%;
        margin-bottom: 2%;
        box-shadow: 0px 2px 6px #ddd;
        border: 1px solid #ddd;
        .imgauto {
            width: 100%;
            height: 1.185rem;
            img {
                transform: scale(1);
                transition: .5s;
            }
        }
        span {
            font-size: .11rem;
            line-height: .3rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            color: #666;
            text-align: center;
            display: block;
            padding: 0 3%;
        }
        &:hover {
            .imgauto {
                img {
                    transform: scale(1.2);
                    transition: .5s;
                }
            }
            span {
                background: #036eb8;
                color: #fff;
            }
        }
        &:nth-child(2n){
            margin-right: 0;
        }
    }
}

.page_tit {
    line-height: .4rem;
    padding: 0 .05rem;
    background: #fff;
    border-top: 10px solid #f3f4f6;
    border-bottom: 10px solid #f3f4f6;
    span {
        font-size: .14rem;
        color: #555;
        float: left;
        font-weight: bold;
        padding-left: 16px;
        background: url(../img/hjtr.png) no-repeat left center;
    }
}

.shownew_box {
    padding: 0 .05rem;
}

.content_tit {
    h3 {
        font-size: .14rem;
        color: #666;
        padding-top: .1rem;
        line-height: 150%;
    }
    p {
        width: 100%;
        line-height: 48px;
        border-bottom: 1px solid #eee;
        span {
            width: 50%;
            float: left;
            font-size: .1rem;
            color: #999;
            padding-left: .1rem;
            background: url(../img/time.png) no-repeat left center;
            background-size: .08rem;
            &:last-child {
                padding-left: 0;
                background: none;
                text-align: right;
            }
        }
    }
}

.content {
    padding-bottom: 20px;
    p {
        font-size: .105rem;
        line-height: .2rem;
        margin-top: .05rem;
    }
    img {
        display: block;
        max-width: 100%;
    }
}

.show_pages {
    padding-top: .05rem;
    padding-bottom: .1rem;
    border-top: 1px solid #eee;
    .pagefl {
        width: 100%;
        p {
            font-size: .105rem;
            color: #666;
            line-height: 100%;
            margin-top: .09rem;
            a {
                font-size: .105rem;
                line-height: 100%;
                color: #666;
            }
        }
    }
    .pagefr {
        width: 100%;
        .share {
            margin-top: .2rem;
            p {
                float: left;
                width: .3rem;
                margin-right: .2rem;
                img {
                    display: block;
                    margin: 0 auto;
                }
                span {
                    font-size: .105rem;
                    color: #555;
                    display: block;
                    text-align: center;
                    margin-top: 6px;
                }
            }
        }
    }
}

.show_zhtext5 {
    .hot_art {
        padding: 0 10px;
        background: #fff;
        li {
            line-height: .26rem;
            width: 100%;
            float: left;
            margin: .02rem 0;
            border-bottom: 1px dotted #eee;
            a {
                width: 60%;
                font-size: .11rem;
                color: #666;
                display: block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                padding-left: 12px;
                background: url(../img/dian.png) no-repeat left center;
                float: left;
                line-height: .26rem;
            }
            span {
                float: right;
                font-size: .09rem;
                color: #b3b3b3;
                line-height: .26rem;
            }

        }
    }
}

.spro_text {
    .contrnt_protit {
        h3 {
            text-align: center;
            display: block;
        }
    }
    .pic {
        margin-top: .03rem;
        img {
            display: block;
            max-width: 100%;
        }
    }
}

















/****************************** media ******************************/
